import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ searchTerm, setSearchTerm, allSearchOptions, maxSearchResults, onOptionClick }) => {
  const [showAllResults, setShowAllResults] = useState(false);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setShowAllResults(false);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setShowAllResults(false);
  };

  const searchOptions = showAllResults
    ? allSearchOptions
    : allSearchOptions.slice(0, maxSearchResults);
  const moreResults = allSearchOptions.length > maxSearchResults && !showAllResults;

  return (
    <div className="search-container">
      <FontAwesomeIcon icon={faSearch} className="search-icon" />
      <input
        type="text"
        className="search-input"
        placeholder="Search by team name, employee name, or employee number"
        value={searchTerm}
        onChange={handleSearch}
      />
      {searchTerm && (
        <FontAwesomeIcon icon={faTimes} className="clear-icon" onClick={clearSearch} />
      )}
      {searchTerm && (
        <div className="search-options-container">
          <ul className="search-options">
            {searchOptions.map((option, index) => (
              <li key={index} onClick={() => onOptionClick(option)}>
                {option.type === 'team' ? `Team: ${option.name}` : `Employee: ${option.name}`}
              </li>
            ))}
          </ul>
          {moreResults && (
            <button
              className="more-results-button"
              onClick={() => setShowAllResults(true)}
            >
              More results available...
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
