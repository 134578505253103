import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import "./CreateEmployee.css";

const CreateEmployee = ({ onSave }) => {
  const initialEmployeeDetails = {
    name: "",
    workEmail: "",
    personalEmail: "",
    idNumber: "",
    dateOfBirth: "",
    dietaryRequirements: "",
    vehicleRegistration: "",
    cell: "",
    address: "",
    region: "",
    profilePicture: null,
  };

  const [formDetails, setFormDetails] = useState(initialEmployeeDetails);
  const [profilePreview, setProfilePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "profilePicture" && files && files[0]) {
      const file = files[0];
      setFormDetails((prevDetails) => ({
        ...prevDetails,
        profilePicture: file,
      }));
      setProfilePreview(URL.createObjectURL(file));
    } else {
      setFormDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleClear = () => {
    setFormDetails(initialEmployeeDetails);
    setProfilePreview(null);
  };

  const handleSave = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to save the employee details?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'var(--second-color)',
      cancelButtonColor: 'var(--red-color)',
      confirmButtonText: 'Yes, save it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // To Do: Process save
        Swal.fire({
          title: 'Success!',
          text: 'Employee details have been saved.',
          icon: 'success',
          confirmButtonColor: 'var(--second-color)',
          html: profilePreview ? `<img src="${profilePreview}" alt="Profile Picture" style="width: 100px; height: 100px; border-radius: 50%;" />` : '',
        });
        handleClear();
      }
    });
  };

  const handleUploadClick = () => {
    document.getElementById("profilePictureUpload").click();
  };

  return (
    <div className="create-employee">
      <h2>Add New Employee</h2>
      <div className="create-employee-form">
        {Object.keys(initialEmployeeDetails).map((key) => (
          key !== "profilePicture" ? (
            <div className="form-row" key={key}>
              <label><strong>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</strong></label>
              <input
                type={key === 'dateOfBirth' ? 'date' : key.includes('Email') ? 'email' : 'text'}
                name={key}
                value={formDetails[key]}
                onChange={handleChange}
                className="editable"
              />
            </div>
          ) : (
            <div className="form-row profile-picture-upload" key={key}>
              <label><strong>Profile Picture:</strong></label>
              <button className="upload-button" onClick={handleUploadClick}>
                <FontAwesomeIcon icon={faUpload} /> Upload
              </button>
              <input
                type="file"
                id="profilePictureUpload"
                name={key}
                accept="image/*"
                onChange={handleChange}
                style={{ display: 'none' }}
              />
            </div>
          )
        ))}
        <div className="profile-picture-preview">
          {profilePreview ? (
            <img src={profilePreview} alt="Profile Preview" />
          ) : (
            <div className="placeholder">No profile picture selected</div>
          )}
        </div>
        <div className="button-wrapper">
          <button className="save-button" onClick={handleSave}>
            <FontAwesomeIcon className="icon-buttons" icon={faSave} /> Save
          </button>
          <button className="cancel-button" onClick={handleClear}>
            <FontAwesomeIcon className="icon-buttons" icon={faTimes} /> Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateEmployee;
