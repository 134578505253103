import React, { useState } from 'react';
import './TeamTable.css';
import SearchBar from './SearchBar';
import TeamList from './TeamList';
import EmployeeDashboard from '../../pages/management/dashboard/employeeDashboard/EmployeeDashboard';

const TeamTable = ({ teamData, expandedTeams, setExpandedTeams, searchTerm, setSearchTerm }) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const maxSearchResults = 10;

  const toggleTeam = (teamName) => {
    setExpandedTeams((prev) =>
      prev.includes(teamName)
        ? prev.filter((name) => name !== teamName)
        : [...prev, teamName]
    );
  };

  const highlightText = (text, term) => {
    if (!term) return text;
    const regex = new RegExp(`(${term})`, 'gi');
    return text.split(regex).map((part, i) =>
      regex.test(part) ? <span key={i} className="highlight">{part}</span> : part
    );
  };

  const allSearchOptions = searchTerm
    ? [
        ...teamData
          .filter((team) =>
            team.teamName.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((team) => ({ type: 'team', name: team.teamName })),
        ...teamData.flatMap((team) =>
          team.employees
            .filter((employee) =>
              employee.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              employee.number.toString().includes(searchTerm)
            )
            .map((employee) => ({ type: 'employee', name: employee.name, ...employee }))
        ),
      ]
    : [];

  const handleOptionClick = (option) => {
    if (option.type === 'team') {
      toggleTeam(option.name);
    } else {
      setSelectedEmployee(option);
    }
  };

  return (
    <div className="table-container">
      {selectedEmployee ? (
        <EmployeeDashboard employee={selectedEmployee} onBack={() => setSelectedEmployee(null)} />
      ) : (
        <>
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            allSearchOptions={allSearchOptions}
            maxSearchResults={maxSearchResults}
            onOptionClick={handleOptionClick}
          />
          <h2 className="title">Teams and Employees</h2>
          <TeamList
            teamData={teamData}
            expandedTeams={expandedTeams}
            toggleTeam={toggleTeam}
            highlightText={highlightText}
            searchTerm={searchTerm}
            onEmployeeClick={setSelectedEmployee}
          />
        </>
      )}
    </div>
  );
};

export default TeamTable;
