import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./PersonalDetails.css";

const PersonalDetails = ({
  details,
  onSave,
  onCancel,
  isEditing,
  handleChange,
  handleEdit,
}) => {
  return (
    <div className="personal-details">
      <h2>Personal Details</h2>
      <div className="personal-details-form">
        <div className="form-row">
          <label><strong>Name:</strong></label>
          <input
            type="text"
            name="name"
            value={details.name}
            onChange={handleChange}
            disabled={!isEditing}
            className={isEditing ? "editable" : "read-only"}
          />
        </div>
        <div className="form-row">
          <label><strong>Work Email Address:</strong></label>
          <input
            type="email"
            name="workEmail"
            value={details.workEmail}
            onChange={handleChange}
            disabled={!isEditing}
            className={isEditing ? "editable" : "read-only"}
          />
        </div>
        <div className="form-row">
          <label><strong>Personal Email Address:</strong></label>
          <input
            type="email"
            name="personalEmail"
            value={details.personalEmail}
            onChange={handleChange}
            disabled={!isEditing}
            className={isEditing ? "editable" : "read-only"}
          />
        </div>
        <div className="form-row">
          <label><strong>ID/Passport Number:</strong></label>
          <input
            type="text"
            name="idNumber"
            value={details.idNumber}
            onChange={handleChange}
            disabled={!isEditing}
            className={isEditing ? "editable" : "read-only"}
          />
        </div>
        <div className="form-row">
          <label><strong>Date of Birth:</strong></label>
          <input
            type="date"
            name="dateOfBirth"
            value={details.dateOfBirth}
            onChange={handleChange}
            disabled={!isEditing}
            className={isEditing ? "editable" : "read-only"}
          />
        </div>
        <div className="form-row">
          <label><strong>Dietary Requirements:</strong></label>
          <input
            type="text"
            name="dietaryRequirements"
            value={details.dietaryRequirements}
            onChange={handleChange}
            disabled={!isEditing}
            className={isEditing ? "editable" : "read-only"}
          />
        </div>
        <div className="form-row">
          <label><strong>Vehicle Registration:</strong></label>
          <input
            type="text"
            name="vehicleRegistration"
            value={details.vehicleRegistration}
            onChange={handleChange}
            disabled={!isEditing}
            className={isEditing ? "editable" : "read-only"}
          />
        </div>
        <div className="form-row">
          <label><strong>Cell:</strong></label>
          <input
            type="text"
            name="cell"
            value={details.cell}
            onChange={handleChange}
            disabled={!isEditing}
            className={isEditing ? "editable" : "read-only"}
          />
        </div>
        <div className="form-row">
          <label><strong>Address:</strong></label>
          <input
            type="text"
            name="address"
            value={details.address}
            onChange={handleChange}
            disabled={!isEditing}
            className={isEditing ? "editable" : "read-only"}
          />
        </div>
        <div className="form-row">
          <label><strong>Region:</strong></label>
          <input
            type="text"
            name="region"
            value={details.region}
            onChange={handleChange}
            disabled={!isEditing}
            className={isEditing ? "editable" : "read-only"}
          />
        </div>
        <div className="button-wrapper">
          {isEditing ? (
            <>
              <button className="save-button" onClick={onSave}>
                <FontAwesomeIcon className="icon-buttons" icon={faSave} /> Save
              </button>
              <button className="cancel-button" onClick={onCancel}>
                <FontAwesomeIcon className="icon-buttons" icon={faTimes} /> Cancel
              </button>
            </>
          ) : (
            <button className="edit-button" onClick={handleEdit}>
              <FontAwesomeIcon className="icon-buttons" icon={faEdit} /> Edit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
