import React from 'react';
import { Tooltip } from 'react-tooltip';

const TeamList = ({ teamData, expandedTeams, toggleTeam, highlightText, searchTerm, onEmployeeClick }) => {
  return (
    <table>
      <thead>
        <tr>
          <th className="heading-team">Team Name</th>
          <th className="heading-team">Primary Manager</th>
          <th className="heading-team">Secondary Manager</th>
          <th className="heading-team">Team Size</th>
          <th className="heading-team"></th>
        </tr>
      </thead>
      <tbody>
        {teamData.map((team) => (
          <React.Fragment key={team.teamName}>
            <tr
              className={`team-row ${
                expandedTeams.includes(team.teamName) ? 'active-team' : ''
              }`}
              onClick={() => toggleTeam(team.teamName)}
            >
              <td>{highlightText(team.teamName, searchTerm)}</td>
              <td>{team.primaryManager}</td>
              <td>{team.secondaryManager || 'N/A'}</td>
              <td>{team.employees.length}</td>
              <td style={{ textAlign: 'right' }}>
                <svg
                  className={`dropdown-icon ${
                    expandedTeams.includes(team.teamName) ? 'expanded' : ''
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 3a.5.5 0 0 1 .832-.374L8 8.226l5.668-5.6A.5.5 0 1 1 14.5 3.374l-6 5.944a.5.5 0 0 1-.832 0l-6-5.944A.5.5 0 0 1 1.5 3z"
                  />
                </svg>
              </td>
            </tr>
            <tr>
              <td colSpan="5" style={{ padding: 0 }}>
                <div
                  className={`employee-details ${
                    expandedTeams.includes(team.teamName)
                      ? 'expanded'
                      : 'collapsed'
                  }`}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>Employee Number</th>
                        <th>Name and Surname</th>
                        <th>Average Self Rating</th>
                        <th>Average Manager Rating</th>
                        <th>Total Goal Progress</th>
                      </tr>
                    </thead>
                    <tbody>
                      {team.employees.map((employee) => (
                        <tr
                          key={employee.number}
                          className='employee-row'
                          onClick={() => onEmployeeClick(employee)}
                        >
                          <td>{employee.number}</td>
                          <td>{employee.name}</td>
                          <td>{employee.selfRating}</td>
                          <td>{employee.managerRating}</td>
                          <td>
                            <div
                              className="progress-bar"
                              data-tooltip-id={`tooltip-${employee.number}`}
                              data-tooltip-content={`${employee.progress}%`}
                              style={{
                                backgroundColor:
                                  employee.progress >= 75
                                    ? '#B6E2CB'
                                    : employee.progress >= 50
                                    ? '#FCDEAC'
                                    : '#E6B0AC',
                              }}
                            >
                              <div
                                className="progress"
                                style={{
                                  '--progress-width': `${employee.progress}%`,
                                  backgroundColor:
                                    employee.progress >= 75
                                      ? '#75CB9A'
                                      : employee.progress >= 50
                                      ? '#F5BF65'
                                      : '#E97A5C',
                                }}
                              ></div>
                            </div>
                            <Tooltip id={`tooltip-${employee.number}`} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default TeamList;
