import React from "react";
import { Tooltip } from "react-tooltip";
import "./TopEmployeesTable.css";

const TopEmployeesTable = ({ employees }) => {
  const sortedEmployees = employees.sort((a, b) => b.rating - a.rating);

  return (
    <div className="top-employees">
      <h2>Top Employees According to Their Ratings</h2>
      <table>
        <thead>
          <tr>
            <th>Employee Number</th>
            <th>Name</th>
            <th>Team</th>
            <th>Average Self Rating</th>
            <th>Average Manager Rating</th>
            <th>Goal Progress</th>
          </tr>
        </thead>
        <tbody>
          {sortedEmployees.map((employee) => (
            <tr key={employee.number}>
              <td>{employee.number}</td>
              <td>{employee.name}</td>
              <td>{employee.team}</td>
              <td>{employee.selfRating}</td>
              <td>{employee.managerRating}</td>
              <td>
                <div
                  className="progress-bar"
                  data-tooltip-id={`tooltip-${employee.number}`}
                  data-tooltip-content={`${employee.progress}%`}
                  style={{
                    backgroundColor:
                      employee.progress >= 75
                        ? "#B6E2CB"
                        : employee.progress >= 50
                        ? "#FCDEAC"
                        : "#E6B0AC",
                  }}
                >
                  <div
                    className="progress"
                    style={{
                      "--progress-width": `${employee.progress}%`,
                      backgroundColor:
                        employee.progress >= 75
                          ? "#75CB9A"
                          : employee.progress >= 50
                          ? "#F5BF65"
                          : "#E97A5C",
                    }}
                  ></div>
                </div>
                <Tooltip id={`tooltip-${employee.number}`} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopEmployeesTable;
