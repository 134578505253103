import React, { useState } from "react";
import DashboardButton from "../../../components/dashboardButton/DashboardButton";
import Dashboard from "../dashboard/Dashboard";
import CreateEmployee from "../dashboard/newEmployee/CreateEmployee";
import EmployeesDashboard from "../dashboard/Employees";
import TeamManagement from "../dashboard/teamManagement/TeamManagement";
import ManageReview from "../dashboard/manageReviewDashboard/ManageReviewDashboard";
import "./ManagementHome.css";

const kpiIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="3" width="7" height="7"></rect>
    <rect x="14" y="3" width="7" height="7"></rect>
    <rect x="14" y="14" width="7" height="7"></rect>
    <rect x="3" y="14" width="7" height="7"></rect>
  </svg>
);

const peopleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M17 21v-2a4 4 0 0 0-4-4H7a4 4 0 0 0-4 4v2"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
  </svg>
);

const addEmployeeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M17 21v-2a4 4 0 0 0-4-4H7a4 4 0 0 0-4 4v2"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M20 8v6"></path>
    <path d="M17 11h6"></path>
  </svg>
);

const manageTeamsIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="3"></circle>
    <path d="M12 3v6"></path>
    <path d="M12 15v6"></path>
    <path d="M3 12h6"></path>
    <path d="M15 12h6"></path>
    <circle cx="4.5" cy="4.5" r="2.5"></circle>
    <circle cx="19.5" cy="4.5" r="2.5"></circle>
    <circle cx="4.5" cy="19.5" r="2.5"></circle>
    <circle cx="19.5" cy="19.5" r="2.5"></circle>
  </svg>
);

const manageReview = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M9 11l3 3L22 4" />
    <path d="M21 12v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h11" />
    <rect x="9" y="2" width="6" height="4" rx="1" ry="1" />
  </svg>
);

const ManagementHome = () => {
  const [activeDashboard, setActiveDashboard] = useState("KPI");

  const handleButtonClick = (dashboard) => {
    setActiveDashboard(dashboard);
  };

  return (
    <div className="main">
      <div className="kpi_nav">
        <DashboardButton
          icon={kpiIcon}
          label="KPI Dashboard"
          isActive={activeDashboard === "KPI"}
          onClick={() => handleButtonClick("KPI")}
        />
        <DashboardButton
          icon={peopleIcon}
          label="Employees"
          isActive={activeDashboard === "Employees"}
          onClick={() => handleButtonClick("Employees")}
        />
        <DashboardButton
          icon={manageTeamsIcon}
          label="Manage Teams"
          isActive={activeDashboard === "Manage-Teams"}
          onClick={() => handleButtonClick("Manage-Teams")}
        />
        <DashboardButton
          icon={addEmployeeIcon}
          label="Add New Employee"
          isActive={activeDashboard === "Add-Employee"}
          onClick={() => handleButtonClick("Add-Employee")}
        />
        <DashboardButton
          icon={manageReview}
          label="Manage Review"
          isActive={activeDashboard === "Manage-Review"}
          onClick={() => handleButtonClick("Manage-Review")}
        />
      </div>
      <div className="dashboard-container">
        {activeDashboard === "KPI" && <Dashboard />}
        {activeDashboard === "Employees" && <EmployeesDashboard />}
        {activeDashboard === "Manage-Teams" && <TeamManagement />}
        {activeDashboard === "Add-Employee" && <CreateEmployee />}
        {activeDashboard === "Manage-Review" && <ManageReview />}
      </div>
    </div>
  );
};

export default ManagementHome;
