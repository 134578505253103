import React, { useState } from 'react';
import './TeamManagement.css';

const mockTeams = [
  { id: 1, name: 'Development Team', description: 'Responsible for developing and maintaining the product.', inCharge: 'John Doe' },
  { id: 2, name: 'Marketing Team', description: 'Handles marketing strategies and campaigns.', inCharge: 'Jane Smith' },
  { id: 3, name: 'Sales Team', description: 'Manages sales and customer relationships.', inCharge: 'Tom Brown' },
];

const TeamManagement = () => {
  const [teams, setTeams] = useState(mockTeams);
  const [teamName, setTeamName] = useState('');
  const [teamDescription, setTeamDescription] = useState('');
  const [personInCharge, setPersonInCharge] = useState('');
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [editInCharge, setEditInCharge] = useState('');

  const handleAddTeam = () => {
    if (teamName.trim() === '' || teamDescription.trim() === '' || personInCharge.trim() === '') return;
    setTeams([...teams, { name: teamName, description: teamDescription, inCharge: personInCharge, id: Date.now() }]);
    setTeamName('');
    setTeamDescription('');
    setPersonInCharge('');
  };

  const handleDeleteTeam = (teamId) => {
    setTeams(teams.filter(team => team.id !== teamId));
    setSelectedTeamId(null);
  };

  const handleSelectTeam = (teamId) => {
    setSelectedTeamId(teamId === selectedTeamId ? null : teamId);
    setEditInCharge('');
  };

  const handleEditInCharge = (teamId) => {
    setTeams(teams.map(team => team.id === teamId ? { ...team, inCharge: editInCharge } : team));
    if (selectedTeamId === teamId) {
      setEditInCharge('');
    }
  };

  return (
    <div className="manage-teams">
      <h2>Team Management</h2>
      <div className="add-team">
        <input
          type="text"
          placeholder="Enter team name"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter team description"
          value={teamDescription}
          onChange={(e) => setTeamDescription(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter person in charge"
          value={personInCharge}
          onChange={(e) => setPersonInCharge(e.target.value)}
        />
        <button className="add-button" onClick={handleAddTeam}>Add Team</button>
      </div>
      <h3 className="existing-teams-heading">Existing Teams</h3>
      <div className="team-list">
        {teams.map((team) => (
          <div key={team.id} className={`team-item ${selectedTeamId === team.id ? 'selected' : ''}`}>
            <div className="team-header" onClick={() => handleSelectTeam(team.id)}>
              <span>{team.name}</span>
              <button onClick={(e) => { e.stopPropagation(); handleDeleteTeam(team.id); }}>Delete</button>
            </div>
            {selectedTeamId === team.id && (
              <div className="team-details">
                <p>{team.description}</p>
                <div className="edit-in-charge">
                  <label>Person in charge: {team.inCharge}</label>
                  <input
                    type="text"
                    placeholder="Enter new person in charge"
                    value={editInCharge}
                    onChange={(e) => setEditInCharge(e.target.value)}
                  />
                  <button className="edit-button" onClick={() => handleEditInCharge(team.id)}>Change</button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamManagement;
