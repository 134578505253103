import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import EmployeeCard from "../../../../components/employeeCard/EmployeeCard";
import PersonalDetails from "../../../../components/personalDetails/PersonalDetails";
import KPIComponent from "../../../../components/kpiComponent/KPIComponent";
import "./EmployeeDashboard.css";
const initialEmployeeDetails = {
  location: "South Africa - Pretoria",
  jobTitle: "Software Engineer",
  currentTeam: {
    name: "DevOps",
    startDate: "2023-01-15",
    primaryManager: "Dirk Botha",
    secondaryManager: "Kayla de Beer",
  },
  previousTeams: [
    {
      name: "TechOps",
      startDate: "2022-05-01",
      endDate: "2023-01-14",
    },
    {
      name: "Support",
      startDate: "2020-08-01",
      endDate: "2022-04-30",
    },
  ],
  name: "John Doe",
  workEmail: "john.doe@example.com",
  personalEmail: "johndoe@gmail.com",
  idNumber: "9876543210123",
  dateOfBirth: "1990-02-15",
  dietaryRequirements: "None",
  vehicleRegistration: "None",
  cell: "07123456789",
  address: "123 Baker Street, Pretoria, W1U 3BW",
  region: "South Africa - Pretoria",
  kpiData: [
    {
      sectionName: "Financial",
      kpis: [
        {
          description: "Increase revenue by exploring new markets, enhancing sales strategies, and optimising pricing models to achieve sustained financial growth over the next fiscal year",
          goal: "Achieve a minimum of 10% revenue growth by the end of Q4, with a focus on expanding into three new international markets and increasing sales by 15% in existing markets",
          weight: 20,
          ratingScale: {
            1: "Poor: Revenue growth was less than 2%, with minimal effort made towards exploring new markets or optimising sales strategies",
            2: "Below Expectations: Revenue growth was between 2% and 5%, with some effort made towards exploring new markets but significant gaps in strategy implementation",
            3: "Meets Expectations: Revenue growth was between 5% and 10%, with moderate success in exploring new markets and implementing sales strategies",
            4: "Exceeds Expectations: Revenue growth was between 10% and 15%, with substantial success in expanding into new markets and optimising sales strategies",
            5: "Outstanding: Revenue growth exceeded 15%, with exceptional success in exploring new markets, enhancing sales strategies, and optimising pricing models",
          },
          supportingEvidence: "Comprehensive Q1 and Q2 revenue reports, including detailed market analysis, sales strategy documentation, and pricing model adjustments",
          achievements: "Achieved an 8% revenue growth by the end of Q2, successfully expanded into two new international markets, and increased sales by 12% in existing markets",
          selfRating: 3,
          impactTotalRatingSelf: 0.6, // (3 * 20) / 100
          managerComments: "Good effort, but fell short of the 10% goal. While the expansion into two new markets and a 12% sales increase is commendable, there is room for improvement in strategy execution",
          managerRating: 2,
          impactTotalRatingManager: 0.4, // (2 * 20) / 100
        },
        {
          description: "Reduce operational costs",
          goal: "5% reduction",
          weight: 10,
          ratingScale: {
            1: "Poor",
            2: "Below Expectations",
            3: "Meets Expectations",
            4: "Exceeds Expectations",
            5: "Outstanding",
          },
          supportingEvidence: "Operational cost reports",
          achievements: "4% reduction achieved",
          selfRating: 3,
          impactTotalRatingSelf: 0.3, // (3 * 10) / 100
          managerComments: "Solid work, room for improvement",
          managerRating: 3,
          impactTotalRatingManager: 0.3, // (3 * 10) / 100
        },
      ],
      subtotals: {
        totalWeight: 30,
        impactTotalRatingSelf: 0.9, // Sum of impactTotalRatingSelf for all KPIs in this section
        impactTotalRatingManager: 0.7, // Sum of impactTotalRatingManager for all KPIs in this section
      },
    },
    {
      sectionName: "Customer Focus",
      kpis: [
        {
          description: "Improve customer satisfaction",
          goal: "90% satisfaction",
          weight: 25,
          ratingScale: {
            1: "Poor",
            2: "Below Expectations",
            3: "Meets Expectations",
            4: "Exceeds Expectations",
            5: "Outstanding",
          },
          supportingEvidence: "Customer survey results",
          achievements: "85% satisfaction",
          selfRating: 4,
          impactTotalRatingSelf: 1.0, // (4 * 25) / 100
          managerComments: "Great improvement, keep it up",
          managerRating: 4,
          impactTotalRatingManager: 1.0, // (4 * 25) / 100
        },
        {
          description: "Increase customer retention",
          goal: "5% increase",
          weight: 15,
          ratingScale: {
            1: "Poor",
            2: "Below Expectations",
            3: "Meets Expectations",
            4: "Exceeds Expectations",
            5: "Outstanding",
          },
          supportingEvidence: "Retention reports",
          achievements: "4% increase",
          selfRating: 3,
          impactTotalRatingSelf: 0.45, // (3 * 15) / 100
          managerComments: "Good effort, aim for higher",
          managerRating: 3,
          impactTotalRatingManager: 0.45, // (3 * 15) / 100
        },
      ],
      subtotals: {
        totalWeight: 40,
        impactTotalRatingSelf: 1.45, // Sum of impactTotalRatingSelf for all KPIs in this section
        impactTotalRatingManager: 1.45, // Sum of impactTotalRatingManager for all KPIs in this section
      },
    },
    {
      sectionName: "Internal Business Processes",
      kpis: [
        {
          description: "Improve process efficiency",
          goal: "15% improvement",
          weight: 15,
          ratingScale: {
            1: "Poor",
            2: "Below Expectations",
            3: "Meets Expectations",
            4: "Exceeds Expectations",
            5: "Outstanding",
          },
          supportingEvidence: "Process reports",
          achievements: "10% improvement",
          selfRating: 3,
          impactTotalRatingSelf: 0.45, // (3 * 15) / 100
          managerComments: "Decent progress, can do better",
          managerRating: 3,
          impactTotalRatingManager: 0.45, // (3 * 15) / 100
        },
        {
          description: "Implement new software tools",
          goal: "3 new tools",
          weight: 10,
          ratingScale: {
            1: "Poor",
            2: "Below Expectations",
            3: "Meets Expectations",
            4: "Exceeds Expectations",
            5: "Outstanding",
          },
          supportingEvidence: "Tool implementation reports",
          achievements: "2 new tools implemented",
          selfRating: 3,
          impactTotalRatingSelf: 0.3, // (3 * 10) / 100
          managerComments: "Nice start, aim for the goal",
          managerRating: 2,
          impactTotalRatingManager: 0.2, // (2 * 10) / 100
        },
      ],
      subtotals: {
        totalWeight: 25,
        impactTotalRatingSelf: 0.75, // Sum of impactTotalRatingSelf for all KPIs in this section
        impactTotalRatingManager: 0.65, // Sum of impactTotalRatingManager for all KPIs in this section
      },
    },
    {
      sectionName: "Learning and Growth",
      kpis: [
        {
          description: "Complete certification",
          goal: "AWS Certification",
          weight: 5,
          ratingScale: {
            1: "Poor",
            2: "Below Expectations",
            3: "Meets Expectations",
            4: "Exceeds Expectations",
            5: "Outstanding",
          },
          supportingEvidence: "Certification documents",
          achievements: "Certification achieved",
          selfRating: 5,
          impactTotalRatingSelf: 0.25, // (5 * 5) / 100
          managerComments: "Excellent achievement",
          managerRating: 5,
          impactTotalRatingManager: 0.25, // (5 * 5) / 100
        },
        {
          description: "Attend industry conferences",
          goal: "2 conferences",
          weight: 5,
          ratingScale: {
            1: "Poor",
            2: "Below Expectations",
            3: "Meets Expectations",
            4: "Exceeds Expectations",
            5: "Outstanding",
          },
          supportingEvidence: "Conference attendance records",
          achievements: "1 conference attended",
          selfRating: 3,
          impactTotalRatingSelf: 0.15, // (3 * 5) / 100
          managerComments: "Good start, attend one more",
          managerRating: 3,
          impactTotalRatingManager: 0.15, // (3 * 5) / 100
        },
      ],
      subtotals: {
        totalWeight: 10,
        impactTotalRatingSelf: 0.4, // Sum of impactTotalRatingSelf for all KPIs in this section
        impactTotalRatingManager: 0.4, // Sum of impactTotalRatingManager for all KPIs in this section
      },
    },
  ],
  grandTotal: {
    totalWeight: 100,
    impactTotalRatingSelf: 3.5, // Sum of all section subtotals for self rating
    impactTotalRatingManager: 3.2, // Sum of all section subtotals for manager rating
  },
};

const EmployeeDashboard = ({ employee, onBack }) => {
  const [employeeDetails, setEmployeeDetails] = useState(initialEmployeeDetails);
  const [isEditing, setIsEditing] = useState(false);
  const [formDetails, setFormDetails] = useState(initialEmployeeDetails);

  const handleEdit = () => setIsEditing(true);

  const handleSave = () => {
    setEmployeeDetails(formDetails);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setFormDetails(employeeDetails);
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <div className="employee-dashboard">
      <button className="back-button" onClick={onBack}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <div className="dashboard-content">
        <div className="card-details">
          <EmployeeCard employee={employee} employeeDetails={employeeDetails} />
          <PersonalDetails
            details={formDetails}
            onSave={handleSave}
            onCancel={handleCancel}
            isEditing={isEditing}
            handleChange={handleChange}
            handleEdit={handleEdit}
          />
        </div>
      </div>
      <KPIComponent details={employeeDetails} />
    </div>
  );
};

export default EmployeeDashboard;
