import React from "react";
import PropTypes from "prop-types";
import "./DashboardButton.css";

const DashboardButton = ({ icon, label, isActive, onClick }) => {
  return (
    <button
      className={`dashboard-links ${isActive ? "active" : ""}`}
      onClick={onClick}
    >
      <div className="icon">{icon}</div>
      <span>{label}</span>
    </button>
  );
};

DashboardButton.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DashboardButton;
