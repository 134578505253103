import './App.css';
import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/navigation/Navbar";
import ManagementHome from "./pages/management/home/ManagementHome";
import Login from './pages/Login/Login';
import ForgotPassword from './components/forgotPassword/ForgotPassword';

function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

function MainContent() {
  const location = useLocation();
  const hideNavbar = location.pathname === '/login' || location.pathname === '/forgot-password';

  return (
    <>
      {!hideNavbar && <Navbar className="nav-bar" />}
      <main className="main-content">
        <Routes>
          <Route path="/dashboard" element={<ManagementHome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </main>
    </>
  );
}

export default App;
