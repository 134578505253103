import React from "react";
import TopEmployeesTable from "../../../components/topEmployeesTable/TopEmployeesTable";
import EmployeeRatingBarChart from "../../../components/charts/employeeRatingBarChart/EmployeeRatingBarChart";
import TeamPerformancePieChart from "../../../components/charts/teamPerformancePieChart/TeamPerformancePieChart";

const employeeStats = {
  Teams: 15,
  Employees: 200,
  "Average Employee Age": "50 y.o.",
  "Average Employee Tenure": "3.6 years",
  "Average Performance Rating": "4.5",
  "Gender Diversity Ratio": "58.83%",
};

const teamRatings = [
  { name: "Development", averageRating: 4.6 },
  { name: "Marketing", averageRating: 4.5 },
  { name: "Sales", averageRating: 4.2 },
  { name: "Team Lead", averageRating: 4.0 },
];

const topEmployees = [
  {
    number: "SOF10",
    name: "Alice Johnson",
    team: "Development",
    selfRating: 4.9,
    managerRating: 4.9,
    progress: 90,
  },
  {
    number: "MK15",
    name: "Bob Smith",
    team: "Marketing",
    selfRating: 4.8,
    managerRating: 4.9,
    progress: 80,
  },
  {
    number: "SA23",
    name: "Charlie Brown",
    team: "Sales",
    selfRating: 4.7,
    managerRating: 4.9,
    progress: 70,
  },
  {
    number: "SA24",
    name: "Eric Dekker",
    team: "Sales",
    selfRating: 4.3,
    managerRating: 4.9,
    progress: 70,
  },
  {
    number: "SOF08",
    name: "Kevin du Tiot",
    team: "Team Lead",
    selfRating: 4.2,
    managerRating: 4.1,
    progress: 10,
  },
];

const allEmployeeRatings = [
  { rating: 1, selfCount: 2, managerCount: 3 },
  { rating: 2, selfCount: 5, managerCount: 5 },
  { rating: 3, selfCount: 15, managerCount: 15 },
  { rating: 4, selfCount: 20, managerCount: 30 },
  { rating: 5, selfCount: 10, managerCount: 5 },
];

const Dashboard = () => {
  return (
    <div className="dashboard">
      <div className="employee_info">
        {Object.entries(employeeStats).map(([key, value]) => (
          <div className="info" key={key}>
            <span className="employee-text-description">{key}</span>
            <span className="employee-text-value">{value}</span>
          </div>
        ))}
      </div>
      <div className="graphs-main">
        <div className="graph-container">
          <EmployeeRatingBarChart employees={allEmployeeRatings} />
        </div>
        <div className="graph-container">
          <TeamPerformancePieChart teamRatings={teamRatings} />
        </div>
        <TopEmployeesTable employees={topEmployees} />
      </div>
    </div>
  );
};

export default Dashboard;
