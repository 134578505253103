import React, { useState } from 'react';
import './ForgotPassword.css';

function ForgotPassword() {
    const [emailAddress, setEmailAddress] = useState('');
    const [emailAddressError, setEmailAddressError] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const validateEmail = (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }    

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateEmail(emailAddress)) {
            setEmailAddressError('Please enter a valid email address.');
            setShowSuccessMessage(false);
        } else {
            setEmailAddressError('');
            setShowSuccessMessage(true);
            console.log('Sending email to', emailAddress);
        }
    };

    const hideSuccessMessage = () => {
        setShowSuccessMessage(false);
    };

    return (
        <div className="forgot-password-page">
            <div className="forgot-password-card">
                <h1 className='heading'>Forgot Password</h1>
                <form onSubmit={handleSubmit}>
                    <div className='user-input'>
                        {emailAddressError && <label className="error-label">{emailAddressError}</label>}
                        <input type="email" className='input-info' placeholder='john.doe@gmail.com' value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />

                        <button type="submit" className="submit-button">Reset Password</button>
                        <span><a href="/login" className="login-nav">Back to Login</a></span>
                    </div>
                </form>
            </div>

            {showSuccessMessage && (
                <div className="success-message">
                    <span id="success-text">Email sent successfully.</span>
                    <button className="close-btn" onClick={hideSuccessMessage}>&#10005;</button>
                </div>
            )}
        </div>
    );
}

export default ForgotPassword;