import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const TeamPerformancePieChart = ({ teamRatings = [] }) => {
  if (!teamRatings.length) {
    return <div>No data available</div>;
  }

  const presetColors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#FFCD56",
  ];

  const teamNames = teamRatings.map((team) => team.name);
  const teamAverageRatings = teamRatings.map((team) => team.averageRating);

  const colors = teamRatings.map((_, index) =>
    index < presetColors.length ? presetColors[index] : getRandomColor()
  );

  const data = {
    labels: teamNames,
    datasets: [
      {
        data: teamAverageRatings,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.raw || 0;
            return `${label}: ${value.toFixed(2)}`;
          },
        },
      },
    },
  };

  const chartContainerStyle = {
    height: "100%",
    width: "100%",
  };

  return (
    <div style={chartContainerStyle}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default TeamPerformancePieChart;
