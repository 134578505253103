import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import defaultProfile from "../../images/defaultProfile.png";
import "./EmployeeCard.css";

const EmployeeCard = ({ employee, employeeDetails, onSaveProfilePic }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [profilePreview, setProfilePreview] = useState(employee.profilePic || defaultProfile);

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const handleUploadClick = () => {
    document.getElementById("profilePicUpload").click();
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePreview(reader.result);
        // To Do: save profile picture
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="card-container">
      <div className={`employee-card ${isFlipped ? "is-flipped" : ""}`}>
        <div className="card-front">
          <div className="profile-pic-container">
            <img
              src={profilePreview}
              alt={`${employee.name}'s profile`}
              className="profile-pic"
            />
            <button className="upload-button" onClick={handleUploadClick}>
              <FontAwesomeIcon className="upload-icon" icon={faUpload} /> Upload
            </button>
            <input
              type="file"
              id="profilePicUpload"
              accept="image/*"
              onChange={handleProfilePicChange}
              style={{ display: 'none' }}
            />
          </div>
          <div className="employee-info">
            <h2>{employee.name}</h2>
            <p className="job-title">{employeeDetails.jobTitle}</p>
            <p>
              <strong>Employee Number:</strong> {employee.number}
            </p>
            <p className="location">{employeeDetails.location}</p>
            <p>
              <strong>Team Name:</strong> {employeeDetails.currentTeam.name}
            </p>
            <p>
              <strong>Start Date:</strong> {employeeDetails.currentTeam.startDate}
            </p>
            {employeeDetails.previousTeams.length > 0 && (
              <button className="toggle-button" onClick={toggleFlip}>
                <span className="toggle-span">View Previous Teams</span> <FontAwesomeIcon icon={faSyncAlt} />
              </button>
            )}
          </div>
        </div>
        <div className="card-back">
          <div className="employee-info">
            <h3>Previous Teams</h3>
            <div className="previous-teams-container">
              <table className="previous-teams-table">
                <thead>
                  <tr>
                    <th>Team Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeDetails.previousTeams.map((team, index) => (
                    <tr key={index}>
                      <td>{team.name}</td>
                      <td>{team.startDate}</td>
                      <td>{team.endDate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button className="toggle-button" onClick={toggleFlip}>
              <span className="toggle-span">View Current Info</span><FontAwesomeIcon icon={faSyncAlt} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeCard;
