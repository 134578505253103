import React, { useState } from 'react';
import TeamTable from '../../../components/teamTable/TeamTable';
import EmployeeDashboard from './employeeDashboard/EmployeeDashboard';

const teamData = [
  {
    teamName: "Development",
    primaryManager: "Eric Dekker",
    secondaryManager: "",
    employees: [
      { number: "SOF10", name: "Alice Johnson", selfRating: 4.9, managerRating: 3.5, progress: 90 },
      { number: "SOF11", name: "Bob Williams", selfRating: 4.2, managerRating: 4.7, progress: 80 },
    ],
  },
  {
    teamName: "Marketing",
    primaryManager: "Arie Croukamp",
    secondaryManager: "Drik Doe",
    employees: [
      { number: "MK15", name: "Charlie Brown", selfRating: 4.8, managerRating: 4.7, progress: 70 },
      { number: "MK16", name: "Diana Ross", selfRating: 4.5, managerRating: 4.8, progress: 60 },
    ],
  },
];

const Employees = () => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [expandedTeams, setExpandedTeams] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleEmployeeClick = (employee) => {
    setSelectedEmployee(employee);
  };

  const handleBackClick = () => {
    setSelectedEmployee(null);
  };

  return (
    <div className="dashboard">
      {selectedEmployee ? (
        <EmployeeDashboard
          employee={selectedEmployee}
          onBack={handleBackClick}
        />
      ) : (
        <TeamTable
          teamData={teamData}
          expandedTeams={expandedTeams}
          setExpandedTeams={setExpandedTeams}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onEmployeeClick={handleEmployeeClick}
        />
      )}
    </div>
  );
};

export default Employees;
