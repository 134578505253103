import React from 'react';
import './KPIComponent.css';

const KPIComponent = ({ details }) => {
  return (
    <div className="kpi-container">
      <h2>KPI Performance Goals</h2>
      {details.kpiData.map((section, index) => (
        <div key={index} className="kpi-section">
          <h3>{section.sectionName.toUpperCase()}</h3>
          <table className="kpi-table">
            <thead>
              <tr>
                <th className="description-col">Description</th>
                <th className="goal-col">Goal</th>
                <th>Weight</th>
                <th className="rating-scale-col">Rating Scale</th>
                <th className="supporting-evidence-col">Supporting Evidence</th>
                <th className="achievements-col">Achievements</th>
                <th>Self Rating</th>
                <th>Impact on Total Rating (Self)</th>
                <th className="manager-comments-col">Manager Comments</th>
                <th>Manager Rating</th>
                <th>Impact on Total Rating (Manager)</th>
              </tr>
            </thead>
            <tbody>
              {section.kpis.map((kpi, kpiIndex) => (
                <tr key={kpiIndex}>
                  <td className="description-col">{kpi.description}</td>
                  <td className="goal-col">{kpi.goal}</td>
                  <td>{kpi.weight}%</td>
                  <td className="rating-scale-col">
                    {Object.entries(kpi.ratingScale).map(([rating, desc]) => (
                      <div key={rating}>
                        <span className={`rating-badge rating-${rating}`}>{rating}</span>
                        <span>{desc}</span>
                      </div>
                    ))}
                  </td>
                  <td className="supporting-evidence-col">{kpi.supportingEvidence}</td>
                  <td>{kpi.achievements}</td>
                  <td>{kpi.selfRating}</td>
                  <td>{kpi.impactTotalRatingSelf}</td>
                  <td className="manager-comments-col">{kpi.managerComments}</td>
                  <td>{kpi.managerRating}</td>
                  <td>{kpi.impactTotalRatingManager}</td>
                </tr>
              ))}
              <tr className="subtotal-row">
                <td colSpan="2">Subtotal</td>
                <td>{section.subtotals.totalWeight}%</td>
                <td colSpan="4"></td>
                <td>{section.subtotals.impactTotalRatingSelf}</td>
                <td colSpan="1"></td>
                <td></td>
                <td>{section.subtotals.impactTotalRatingManager}</td>
              </tr>
              {index === details.kpiData.length - 1 && (
                <tr className="grand-total-row">
                  <td colSpan="2"><strong>Total</strong></td>
                  <td>{details.grandTotal.totalWeight}%</td>
                  <td colSpan="4"></td>
                  <td>{details.grandTotal.impactTotalRatingSelf}</td>
                  <td colSpan="1"></td>
                  <td></td>
                  <td>{details.grandTotal.impactTotalRatingManager}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default KPIComponent;
