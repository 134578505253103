import React, { useState } from "react";
import Swal from "sweetalert2";
import "./ManageReviewDashboard.css";

const initialMockReviewPeriods = [
  { 
    id: 1, 
    startDate: "2021-01-01", 
    endDate: "2021-12-31", 
    rating: "A", 
    initiator: "Alice Johnson", 
    status: "Completed" 
  },
  { 
    id: 2, 
    startDate: "2022-01-01", 
    endDate: "2022-12-31", 
    rating: "B+", 
    initiator: "Bob Smith", 
    status: "Completed" 
  },
  { 
    id: 3, 
    startDate: "2023-01-01", 
    endDate: "", 
    rating: "Pending", 
    initiator: "Charlie Davis", 
    status: "Ongoing" 
  },
];

function ManageReviews() {
  const [reviewPeriods, setReviewPeriods] = useState(initialMockReviewPeriods);

  const handleStartClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to start a new review period?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, start it.",
      cancelButtonText: "Cancel",
      buttonsStyling: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Success!",
          text: "New review period started.",
          icon: "success",
          confirmButtonText: "OK",
        });

        const newPeriod = {
          id: reviewPeriods.length + 1,
          startDate: new Date().toISOString().split('T')[0],
          endDate: "",
          rating: "Pending",
          initiator: "New Initiator", // Replace with the actual initiator
          status: "Ongoing"
        };

        setReviewPeriods((prevPeriods) => {
          const ongoingPeriodIndex = prevPeriods.findIndex(period => period.status === "Ongoing");
          const updatedPeriods = ongoingPeriodIndex !== -1 ? 
            prevPeriods.map((period, index) => index === ongoingPeriodIndex ? { ...period, status: "Completed", endDate: new Date().toISOString().split('T')[0] } : period) : 
            prevPeriods;
          
          return updatedPeriods.length > 3 ? 
            [...updatedPeriods.slice(1), newPeriod] : 
            [...updatedPeriods, newPeriod];
        });
      }
    });
  };

  const handleEndClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to end the review period?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, end it.",
      cancelButtonText: "Cancel",
      buttonsStyling: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Success!",
          text: "Review period ended.",
          icon: "success",
          confirmButtonText: "OK",
        });

        setReviewPeriods((prevPeriods) => {
          const ongoingPeriodIndex = prevPeriods.findIndex(period => period.status === "Ongoing");
          if (ongoingPeriodIndex === -1) return prevPeriods;

          const updatedPeriods = prevPeriods.map((period, index) => 
            index === ongoingPeriodIndex ? { ...period, status: "Completed", endDate: new Date().toISOString().split('T')[0] } : period
          );

          return updatedPeriods.length > 3 ? updatedPeriods.slice(1) : updatedPeriods;
        });
      }
    });
  };

  const currentPeriod = reviewPeriods.find(period => period.status === "Ongoing");

  return (
    <div className="manage-reviews">
      <div className="manage-reviews-card">
        <h1 className="headings">Manage Review Periods</h1>
        <div className="button-container">
          <button
            type="button"
            className="period-buttons btn-success"
            onClick={handleStartClick}
          >
            Start Period
          </button>
          <button
            type="button"
            className="period-buttons btn-danger"
            onClick={handleEndClick}
          >
            End Period
          </button>
        </div>
        <h2 className="sub-heading">Current Review Period</h2>
        {currentPeriod ? (
          <div className="current-period">
            <div><strong>Start Date:</strong> {currentPeriod.startDate}</div>
            <div><strong>End Date:</strong> {currentPeriod.endDate || 'N/A'}</div>
            <div><strong>Rating:</strong> {currentPeriod.rating}</div>
            <div><strong>Initiator:</strong> {currentPeriod.initiator}</div>
            <div><strong>Status:</strong> {currentPeriod.status}</div>
          </div>
        ) : (
          <div>No ongoing review period.</div>
        )}
        <h2 className="sub-heading">Historical Review Periods</h2>
        <table className="review-periods-table">
          <thead>
            <tr>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Rating</th>
              <th>Initiator</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {reviewPeriods.filter(period => period.status === "Completed").map((period) => (
              <tr key={period.id}>
                <td>{period.startDate}</td>
                <td>{period.endDate}</td>
                <td>{period.rating}</td>
                <td>{period.initiator}</td>
                <td>{period.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ManageReviews;