import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const navigate = useNavigate();

    function ValidateInputs(){
        let isValid = true;

        if (username.length < 8 || username.length > 20) {
            setUsernameError("Please enter a username between 8 and 20 characters.");
            isValid = false;
        } else {
            setUsernameError('');
        }

        if (!password || password.length < 6) {
            setPasswordError("Password must be at least 6 characters long.");
            isValid = false;
        } else {
            setPasswordError('');
        }

        return isValid;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        
        if (ValidateInputs()) {
            console.log('Login attempt:', username, password);
            navigate('/');
        }
    };

    return (
        <div className="login-page">
            <div className="login-card">
                <h1 className='heading'>Login</h1>
                <form onSubmit={handleSubmit}>
                    <div className='user-input'>
                        {usernameError && <label className="error-label">{usernameError}</label>}
                        <input type="text" className='input-info' placeholder='Username' value={username} onChange={(e) => setUsername(e.target.value)} />
                        
                        {passwordError && <label className="error-label">{passwordError}</label>}
                        <input type="password" className='input-info' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />

                        <button type="submit" className="submit-button">Login</button>
                        <a href="/forgot-password" className="forgot-password">Forgot Password?</a>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
