import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EmployeeRatingBarChart = ({ employees }) => {
  const selfRatingCounts = Array(5).fill(0);
  const managerRatingCounts = Array(5).fill(0);

  employees.forEach((object) => {
    selfRatingCounts[object.rating - 1] = object.selfCount;
    managerRatingCounts[object.rating - 1] = object.managerCount;
  });

  const data = {
    labels: ["1", "2", "3", "4", "5"],
    datasets: [
      {
        label: "Self Rating",
        data: selfRatingCounts,
        backgroundColor: "rgba(70, 130, 180, 0.5)",
        borderColor: "rgba(70, 130, 180, 1)",
        borderWidth: 1,
        barPercentage: 0.8,
        categoryPercentage: 0.9,
      },
      {
        label: "Manager Rating",
        data: managerRatingCounts,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        barPercentage: 0.8,
        categoryPercentage: 0.9,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Employee Count by Self and Manager Rating",
      },
    },
    animation: {
      duration: 1000,
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default EmployeeRatingBarChart;
